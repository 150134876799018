import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const StorageCleanup = () => {
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/dashboard') {
     localStorage.removeItem('startandPauseData')
    localStorage.removeItem('selectedUser')
    localStorage.removeItem('errorMessageInput');
    localStorage.removeItem('errorMessageInput1');
    localStorage.removeItem('errorMessageInput2');
    localStorage.removeItem('currentTimeData')
    localStorage.removeItem('OptionalPassenger')
    localStorage.removeItem('driverDetailFormData')
    localStorage.removeItem('tripAndVehicleTypeData')
    localStorage.removeItem('licenseFormData')
    localStorage.removeItem('garbageFormData')
    localStorage.removeItem('signatureDataURL')
    localStorage.removeItem('currentStep')
    localStorage.removeItem(`pauseTime`);
    localStorage.removeItem('co_driver')
    localStorage.removeItem('isdriver')
    localStorage.removeItem('isCheckboxChecked')
    localStorage.removeItem("isSelectedDriver")
    localStorage.removeItem("signatureImage")
    localStorage.removeItem('mileageData')
    localStorage.removeItem('fqnCardDetails')
    localStorage.removeItem('driverCardData')
    localStorage.removeItem('selectedDriver')
      localStorage.removeItem('damagedImages')
      localStorage.removeItem('startTime')
      localStorage.removeItem('selectedVehicle')
      localStorage.removeItem('selectLocation')
    }
  }, [location])

  return null
}

export default StorageCleanup