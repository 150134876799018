import React, { useState } from 'react'
import { updateDetails } from '../services/ApiServices'
import Modal from './Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPen,
  faPlus,
  faTimes,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons'
import { AuthDetails } from '../libs/authDetails'
import useFileUpload from '../hooks/useFileUpload'
import Loader from 'react-js-loader'
import { useMutation, QueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'


interface UserDataType {
  emailId: string | null;
  firstname: string;
  lastname: string;
  license_number: string | null;
  phonenumber: string;
  profile_picture: string;
  user_id: number;
}

const UserUpdate = ({ onClose, userData }: { onClose: () => void; userData?: UserDataType }) => {
  const [user_id, setUser_id] = useState<number>(userData?.user_id || Number)
  const [profile_picture, setProfile_picture] = useState<any>(null)
  const [license_number, setLicense_number] = useState(
    userData?.license_number || ''
  )

  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState(userData?.firstname || '')
  const [lastName, setLastName] = useState(userData?.lastname || '')
  const [phoneNo, setPhoneNo] = useState(userData?.phonenumber || '')
  const { UploadFile } = useFileUpload()
  const { token, username } = AuthDetails()
  const { t } = useTranslation()
  const queryClient = new QueryClient()
  const convertImageToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const updateUserMutation = useMutation(
    async () => {
      setLoading(true);

      let profilePictureUrl = userData?.profile_picture;

      if (profile_picture && profile_picture instanceof File) {
        const timestamp = new Date().getTime();
        const filename = `profile_Picture_${username}_${timestamp}`;
        profilePictureUrl = await UploadFile(profile_picture, filename) as string;
      }

      const response = await updateDetails(
        user_id,
        firstName,
        lastName,
        phoneNo,
        profilePictureUrl || '',
        license_number,
        token
      );

      setLoading(false);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        onClose();
      },
      onError: (error) => {
        setLoading(false);
        console.error('Error:', error);
      }
    }
  );


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setProfile_picture(selectedFile);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setLoading(true)

      await updateUserMutation.mutateAsync()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error:', error)
    }
  }
  if (!userData) {
    return null;
  }

  return (
    <Modal onClose={onClose} contentWidth="30%">
      <div className="modal-header">
        <h4>{t('Update_Details')}</h4>
        <FontAwesomeIcon icon={faTimes} className="close" onClick={onClose} />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex align-items-center justify-content-center mb-2">
          <label
            htmlFor="upload-button"
            className="upload-label"
            style={{ display: 'flex', alignItems: 'end' }}>
            <div
              style={{
                border: '1px solid',
                borderRadius: '50px',
                padding: '2px',
                width: '20px',
                height: '20px',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                marginLeft: '45px',
                cursor: "pointer"
              }}>
              {!userData.profile_picture ? (
                <FontAwesomeIcon icon={faPlus} className="fas fa-icon" />) :
                <FontAwesomeIcon icon={faPen} className="fas fa-icon" />
              }
            </div>
            {userData.profile_picture || profile_picture ? (
  <img
    src={
      profile_picture
        ? (profile_picture instanceof File ? URL.createObjectURL(profile_picture) : profile_picture)
        : userData.profile_picture
    }
    alt="Profile"
    className='profilePicture'
  />
) : (
  <FontAwesomeIcon icon={faUserCircle} className="upload-icon" />
)}
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="upload-button"
            />
          </label>
        </div>

        <label htmlFor="user_id">{t('UserID')}</label>
        <input
          type="text"
          id="user_id"
          value={user_id}
          onChange={(e) => setUser_id(Number(e.target.value))}
          required
          readOnly

        />

        <label htmlFor="first_name" className="mt-2">
          {t('FirstName')}
        </label>
        <input
          type="text"
          id="first_name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />

        <label htmlFor="last_name" className="mt-2">
          {t('LastName')}
        </label>
        <input
          type="text"
          id="last_name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
        <label htmlFor="license_number" className="mt-2">
          {t('LicenseNo')}
        </label>
        <input
          type="text"
          id="license_number"
          value={license_number}
          onChange={(e) => setLicense_number(e.target.value)}
          required
        />
        <label htmlFor="phone_number" className="mt-2">
          {t('PhoneNo')}
        </label>
        <input
          type="text"
          id="phone_number"
          value={phoneNo}
          onChange={(e) => setPhoneNo(e.target.value)}
          required
        />
        <div className="d-flex align-items-center justify-content-center pt-4">
          {loading ? (
            <Loader
              type="bubble-top"
              bgColor={'#05653F'}
              color={'#05653F'}
              size={50}
            />
          ) : (
            <button type="submit" >{t('Update')}</button>
          )}
        </div>
      </form>
    </Modal>
  )
}

export default UserUpdate
